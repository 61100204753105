import * as React from "react"
import MainLayout from '../../layouts/main'
import {useContext, useEffect, useState} from "react";
import GlobalContext from "../../context/GlobalContext";
import {graphql, useStaticQuery} from "gatsby";
import Breadcrumps from "../../components/Breadcrumbs";
import Partners from "../home/partners";
import ContributiFiscali from "../home/contributi-fiscali";
import Help from "../home/help";
import GalleryButton from "../../components/GalleryButton";
import HeroPage from "../../components/HeroPage";
import SectionInstallazioni from "../../components/SectionInstallazioni";
import SeoComponent from "../../layouts/partials/SeoComponent";

const items = [
    { title: 'Prodotti', href: 'prodotti' },
    { title: 'Pergole bioclimatiche' }
]
const installazioni = [
    {
        name: 'Le nostre installazioni',
        gallery: 'installazione-bioclimatica.jpg',
        list: [
            {
                id: '1',
                src: '/prodotti/pergole/PergoleBioclimatiche/Installazione/installazione-01.jpg',
                thumb: '/thumb/pergole/PergoleBioclimatiche/Installazione/installazione-01.jpg',
            },
            {
                id: '2',
                src: '/prodotti/pergole/PergoleBioclimatiche/Installazione/installazione-02.jpg',
                thumb: '/thumb/pergole/PergoleBioclimatiche/Installazione/installazione-02.jpg',
            },
            {
                id: '3',
                src: '/prodotti/pergole/PergoleBioclimatiche/Installazione/installazione-03.jpg',
                thumb: '/thumb/pergole/PergoleBioclimatiche/Installazione/installazione-03.jpg',
            },
            {
                id: '4',
                src: '/prodotti/pergole/PergoleBioclimatiche/Installazione/installazione-04.jpg',
                thumb: '/thumb/pergole/PergoleBioclimatiche/Installazione/installazione-04.jpg',
            },
            {
                id: '5',
                src: '/prodotti/pergole/PergoleBioclimatiche/Installazione/installazione-06.jpeg',
                thumb: '/thumb/pergole/PergoleBioclimatiche/Installazione/installazione-06.jpeg',
            },
            {
                id: '6',
                src: '/prodotti/pergole/PergoleBioclimatiche/Installazione/installazione-07.jpeg',
                thumb: '/thumb/pergole/PergoleBioclimatiche/Installazione/installazione-07.jpeg',
            },
            {
                id: '7',
                src: '/prodotti/pergole/PergoleBioclimatiche/Installazione/installazione-08.jpeg',
                thumb: '/thumb/pergole/PergoleBioclimatiche/Installazione/installazione-08.jpeg',
            },
            {
                id: '8',
                src: '/prodotti/pergole/PergoleBioclimatiche/Installazione/installazione-09.jpeg',
                thumb: '/thumb/pergole/PergoleBioclimatiche/Installazione/installazione-09.jpeg',
            },
            {
                id: '9',
                src: '/prodotti/pergole/PergoleBioclimatiche/Installazione/installazione-10.jpeg',
                thumb: '/thumb/pergole/PergoleBioclimatiche/Installazione/installazione-10.jpeg',
            },
            {
                id: '10',
                src: '/prodotti/pergole/PergoleBioclimatiche/Installazione/poster.jpg',
                thumb: '/thumb/pergole/PergoleBioclimatiche/Installazione/poster.jpg',
            },
            {
                id: '11',
                src: '/prodotti/pergole/PergoleBioclimatiche/Installazione/installazione-video.mp4',
                thumb: '/thumb/pergole/PergoleBioclimatiche/Installazione/installazione-video.jpg',
            },
        ]
    },
]

const PergoleBioclimatiche = ({pageContext}) => {
    const gContext = useContext(GlobalContext);
    const allImagesQuery = graphql`
            query {
              allFile(
                filter: {
                    relativeDirectory: { regex: "/(dettaglio)/" },
                    extension: {ne: "svg"}
                }
              ) {
                edges {
                  node {
                    base
                    childImageSharp {
                      gatsbyImageData(
                        formats: [AUTO, WEBP]       
                        placeholder: NONE        
                        quality: 100
                      )
                    }
                  }
                }
              }
            }
          `
    const {
        allFile: {edges: images},
    } = useStaticQuery(allImagesQuery)
    const [products, setProducts] = useState({});
    useEffect(() => {
        setProducts({
            prodotti: [
                {
                    name: 'Med Twist',
                    gallery: 'med-twist.jpg',
                    items: [
                        {
                            id: '1',
                            src: '/prodotti/pergole/PergoleBioclimatiche/MedTwist/med-twist-01.jpg',
                            thumb: '/thumb/pergole/PergoleBioclimatiche/MedTwist/med-twist-01.jpg',
                        },
                        {
                            id: '2',
                            src: '/prodotti/pergole/PergoleBioclimatiche/MedTwist/med-twist-02.jpg',
                            thumb: '/thumb/pergole/PergoleBioclimatiche/MedTwist/med-twist-02.jpg',
                        },
                        {
                            id: '3',
                            src: '/prodotti/pergole/PergoleBioclimatiche/MedTwist/med-twist-03.jpg',
                            thumb: '/thumb/pergole/PergoleBioclimatiche/MedTwist/med-twist-03.jpg',
                        },
                        {
                            id: '4',
                            src: '/prodotti/pergole/PergoleBioclimatiche/MedTwist/med-twist-04.jpg',
                            thumb: '/thumb/pergole/PergoleBioclimatiche/MedTwist/med-twist-04.jpg',
                        },
                        {
                            id: '5',
                            src: '/prodotti/pergole/PergoleBioclimatiche/MedTwist/med-twist-05.jpg',
                            thumb: '/thumb/pergole/PergoleBioclimatiche/MedTwist/med-twist-05.jpg',
                        },
                        {
                            id: '6',
                            src: '/prodotti/pergole/PergoleBioclimatiche/MedTwist/med-twist-06.jpg',
                            thumb: '/thumb/pergole/PergoleBioclimatiche/MedTwist/med-twist-06.jpg',
                        },
                        {
                            id: '7',
                            src: '/prodotti/pergole/PergoleBioclimatiche/MedTwist/med-twist-07.jpg',
                            thumb: '/thumb/pergole/PergoleBioclimatiche/MedTwist/med-twist-07.jpg',
                        },
                        {
                            id: '8',
                            src: '/prodotti/pergole/PergoleBioclimatiche/MedTwist/med-twist-08.jpg',
                            thumb: '/thumb/pergole/PergoleBioclimatiche/MedTwist/med-twist-08.jpg',
                        },
                    ]
                },
                {
                    name: 'Med Twist',
                    gallery: 'med-twist-14.jpg',
                    items: [
                        {
                            id: '9',
                            src: '/prodotti/pergole/PergoleBioclimatiche/MedTwist/med-twist-09.jpg',
                            thumb: '/thumb/pergole/PergoleBioclimatiche/MedTwist/med-twist-09.jpg',
                        },
                        {
                            id: '10',
                            src: '/prodotti/pergole/PergoleBioclimatiche/MedTwist/med-twist-10.jpg',
                            thumb: '/thumb/pergole/PergoleBioclimatiche/MedTwist/med-twist-10.jpg',
                        },
                        {
                            id: '11',
                            src: '/prodotti/pergole/PergoleBioclimatiche/MedTwist/med-twist-11.jpg',
                            thumb: '/thumb/pergole/PergoleBioclimatiche/MedTwist/med-twist-11.jpg',
                        },
                        {
                            id: '12',
                            src: '/prodotti/pergole/PergoleBioclimatiche/MedTwist/med-twist-12.jpg',
                            thumb: '/thumb/pergole/PergoleBioclimatiche/MedTwist/med-twist-12.jpg',
                        },
                        {
                            id: '13',
                            src: '/prodotti/pergole/PergoleBioclimatiche/MedTwist/med-twist-13.jpg',
                            thumb: '/thumb/pergole/PergoleBioclimatiche/MedTwist/med-twist-13.jpg',
                        },
                        {
                            id: '14',
                            src: '/prodotti/pergole/PergoleBioclimatiche/MedTwist/med-twist-14.jpg',
                            thumb: '/thumb/pergole/PergoleBioclimatiche/MedTwist/med-twist-14.jpg',
                        },
                        {
                            id: '15',
                            src: '/prodotti/pergole/PergoleBioclimatiche/MedTwist/med-twist-15.jpg',
                            thumb: '/thumb/pergole/PergoleBioclimatiche/MedTwist/med-twist-15.jpg',
                        },
                        {
                            id: '16',
                            src: '/prodotti/pergole/PergoleBioclimatiche/MedTwist/med-twist-16.jpg',
                            thumb: '/thumb/pergole/PergoleBioclimatiche/MedTwist/med-twist-16.jpg',
                        },
                    ]
                },
                {
                    name: 'Med Varia',
                    gallery: 'med-varia.jpg',
                    items: [
                        {
                            id: '17',
                            src: '/prodotti/pergole/PergoleBioclimatiche/MedTwist/med-twist-17.jpg',
                            thumb: '/thumb/pergole/PergoleBioclimatiche/MedTwist/med-twist-17.jpg',
                        },
                        {
                            id: '18',
                            src: '/prodotti/pergole/PergoleBioclimatiche/MedVaria/med-varia-01.jpg',
                            thumb: '/thumb/pergole/PergoleBioclimatiche/MedVaria/med-varia-01.jpg',
                        },
                        {
                            id: '19',
                            src: '/prodotti/pergole/PergoleBioclimatiche/MedVaria/med-varia-02.jpg',
                            thumb: '/thumb/pergole/PergoleBioclimatiche/MedVaria/med-varia-02.jpg',
                        },
                        {
                            id: '20',
                            src: '/prodotti/pergole/PergoleBioclimatiche/MedVaria/med-varia-03.jpg',
                            thumb: '/thumb/pergole/PergoleBioclimatiche/MedVaria/med-varia-03.jpg',
                        },
                        {
                            id: '21',
                            src: '/prodotti/pergole/PergoleBioclimatiche/MedVaria/med-varia-04.jpg',
                            thumb: '/thumb/pergole/PergoleBioclimatiche/MedVaria/med-varia-04.jpg',
                        },
                        {
                            id: '22',
                            src: '/prodotti/pergole/PergoleBioclimatiche/MedVaria/med-varia-05.jpg',
                            thumb: '/thumb/pergole/PergoleBioclimatiche/MedVaria/med-varia-05.jpg',
                        },
                        {
                            id: '23',
                            src: '/prodotti/pergole/PergoleBioclimatiche/MedVaria/med-varia-06.jpg',
                            thumb: '/thumb/pergole/PergoleBioclimatiche/MedVaria/med-varia-06.jpg',
                        },
                    ]
                },
            ],
        })
    }, [gContext])
    return (
        <MainLayout pageContext={pageContext}>
            <HeroPage
                image={gContext.getImageFromName(images, 'GIBUS_TWIST_pergola_bioclimatica.jpg')}
                imageAlt={'GIBUS_TWIST_pergola_bioclimatica.jpg'}
                title={'Pergole bioclimatiche'}
            />
            <Breadcrumps  items={items}/>

            <div className="max-w-[1400px] mx-auto py-16 px-4 sm:px-6 lg:px-8 relative w-full">
                <div className="w-full text-justify" dangerouslySetInnerHTML={{ __html: gContext.t('bioclimatiche.intro') }}></div>

                <SectionInstallazioni items={installazioni} images={images} classes={'mb-16'}/>

                <h2 className="title-section-light text-center mb-16">{gContext.t('Tutte le tipologie disponibili')}</h2>
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-x-8 gap-y-8 sm:gap-y-16 mb-8">
                    {(products?.prodotti || []).map((item) => (
                        <div key={item.name} className="min-h-[300px]">
                            <GalleryButton image={gContext.getImageFromName(images, item.gallery)} imageAlt={`image`} items={item.items}/>
                        </div>
                    ))}
                </div>
            </div>


            <ContributiFiscali/>
            <Help/>
            <Partners/>
        </MainLayout>
    )
}

export default PergoleBioclimatiche

export const Head = ({pageContext}) => (
    <SeoComponent pageContext={pageContext} />
)